<template>
  <v-card
      class="align-end"
      max-width="300px"
      outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline">
          артикул {{ product.id }}
        </div>
        <v-list-item-title class="text-h6 mb-4">
          {{ text_for_shotname() }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ product.name }}
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
          tile
          size="80"
      >
        <v-img :src="get_img()"></v-img>
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <span class="subheading">{{ text_for_type() }}</span>
      <v-spacer></v-spacer>
      <v-btn outlined rounded text @click="$emit('show',product)">
        {{ product.price }}฿
      </v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>

import {isEmpty} from 'lodash';

export default {
  name: 'product',
  components: {
    // EditRestaurantDialog
  },
  props: {
    product: Object
  },
  data() {
    return {
      // show: false,
      // name: this.card.name,
      // location: this.card.location,
      // rating: this.card.rating,
      // link: this.card.link,
      // photo: this.getPhotoUrl()
    }
  },
  methods: {

    text_for_shotname() {
      if (isEmpty(this.product.shot_name)) {
        return this.product.name.substring(0, 15)
      } else {
        return this.product.shot_name
      }
    },
    text_for_type() {
      if (this.product.type === 'PRD') {
        return 'товар ' + this.product.quantity + ' шт.'
      } else {
        return 'услуга ' + this.product.quantity + ' дней'
      }
    },
    get_img() {
      if (this.product.type === 'PRD') {
        return require('@/site/modules/shop/assets/5.svg')
      } else {
        return require('@/site/modules/shop/assets/clock.svg')
      }
    }
  },
}
</script>

<style scoped>

</style>